import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { StrictMode, startTransition, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

/*
 * This function is used to determine if an error event is noise from Google Tag Manager.
 */
const isNoise = (event: any): boolean => {
  // Check if the event message indicates an internal Stripe noise signal
  if (typeof event.message === "string" && event.message?.includes("Failed to get setup intent")) {
    const internalDomains = ["g2xchange.com", "milemarker10.com", "govforum.io"];
    const email = event.request?.cookies?.cb_user_id;
    if (email && internalDomains.some((domain) => email.includes(domain))) {
      return false;
    }
  }
  const noiseSignals = ["googletagmanager.com", "gtag.js", "posthog"];
  return event.exception?.values?.[0]?.stacktrace?.frames?.some((f) =>
    noiseSignals.some((signal) => f.filename?.includes(signal)),
  );
};

let SENTRY_DSN = "";
let PACKAGE_VERSION = "";
let SENTRY_ENV = "";

if (typeof window !== "undefined" && window.ENV) {
  SENTRY_DSN = window.ENV.SENTRY_DSN;
  PACKAGE_VERSION = window.ENV.PACKAGE_VERSION;
  SENTRY_ENV = window.ENV.SENTRY_ENV;
} else if (typeof ENV !== "undefined") {
  SENTRY_DSN = ENV.SENTRY_DSN;
  PACKAGE_VERSION = ENV.PACKAGE_VERSION;
  SENTRY_ENV = ENV.SENTRY_ENV;
}

startTransition(() => {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: PACKAGE_VERSION,
    environment: SENTRY_ENV,
    denyUrls: [
      /^chrome-extension:/,
      /^moz-extension:/,
      /.*googletagmanager\.com(\/.*)?$/,
      /.*gtag\.js(\/.*)?$/,
      /.*posthog\.com(\/.*)?$/,
    ],
    beforeSend(event) {
      // Ignore errors from Google Tag Manager
      if (isNoise(event)) {
        return null;
      }
      return event;
    },
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      // Replay is only available in the client
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
      }),
      // Sentry.captureConsoleIntegration(),
      Sentry.browserProfilingIntegration(),
    ],

    // Set tracesSampleRate to capture 40%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.5,
    profilesSampleRate: 0.3,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/qs1\.g2xchange\.com/, /^https:\/\/app\.g2xchange\.com/],

    // Capture Replay for 5% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
  });

  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
